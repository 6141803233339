/*  Author -  Rutvik Gandhi 
    Date - 24/2/2023
    Description - Sign Up Page For User
*/
/* eslint-disable*/
import {
  Grid,
  Typography,
  TextField,
  Button,
  CircularProgress,
  InputLabel,
  Box,
} from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import InputAdornment from '@material-ui/core/InputAdornment';
import PropTypes from 'prop-types';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@material-ui/icons/VisibilityOffOutlined';
import IconButton from '@material-ui/core/IconButton';
import WarningRoundedIcon from '@material-ui/icons/WarningRounded';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import { FastField, Formik, Form, Field } from 'formik';
import { Link, useParams } from 'react-router-dom';
import { object, string } from 'yup';
import ReCAPTCHA from 'react-google-recaptcha';
import '../Auth.style.css';
import useLogin from '../../../hooks/useAuth';
import CustomTextInput from '../../../components/Common/CustomTextInput';
import { showErrIfTouched } from '../../../utils';
import { KEY } from '../../../constants/globalConstants';

const nameRegex = /[A-Za-z]+.*/i;
const passRegex = /(?=^.{8,}$)((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/;
const emailRegex = /\S+@\S+\.\S+/;

const LoginSchema = object().shape({
  firstName: string()
    .required('Required field')
    .matches(nameRegex, 'Please enter a valid first name.'),
  lastName: string()
    .required('Required field')
    .matches(nameRegex, 'Please enter a valid last name.'),
  emailId: string()
    .required('Required field')
    .matches(
      emailRegex,
      'Improper format: Please enter using the correct format (eg. name@email.com)',
    ),
  password: string()
    .required('Required field')
    .matches(passRegex, 'Please enter a valid password.')
    .matches(/^\S*$/, 'Password cannot contain spaces.')
    .matches(/(?=.*?[#?!@$%^&*-])/, 'Please enter a valid password.')
    .matches(/(?=.*?[0-9])/, 'Please enter a valid password.'),
});

const SignUp = ({ setValue, handleClose }) => {
  const [lcase, setLCase] = useState({
    isError: true,
    case: 0,
    message: 'At least one lower case character',
    show: false,
  });
  const [ucase, setUCase] = useState({
    isError: true,
    case: 0,
    message: 'At least one upper case character',
    show: false,
  });
  const [schar, setSChar] = useState({
    isError: true,
    case: 0,
    message: 'At least one special character',
    show: false,
  });
  const [num, setNum] = useState({
    isError: true,
    case: 0,
    message: 'At least one number character',
    show: false,
  });
  const [charlen, setCharLen] = useState({
    isError: true,
    case: 0,
    message: '8 characters minimum',
    show: false,
  });

  // const [data, setData] = useState({});

  // const onChangeHandler1 = (e) => {
  //   const { name, value } = e.target;
  //   setData({ ...data, [name]: value });
  // };

  const onChangeHandler = (e) => {
    const digitsRegExp = /(?=.*?[0-9])/;
    const lowercaseRegExp = /(?=.*?[a-z])/;
    const uppercaseRegExp = /(?=.*?[A-Z])/;
    const specialCharRegExp = /(?=.*?[#?!@$%^&*-])/;
    const { value } = e.target;
    // setData({ ...data, password: value });

    if (value.length >= 8) {
      setCharLen({ ...charlen, isError: false, case: 0, show: true });
    } else {
      setCharLen({ ...charlen, isError: true, case: 0, show: true });
    }
    if (digitsRegExp.test(value)) {
      setNum({ ...num, isError: false, case: 0, show: true });
    } else {
      setNum({ ...num, isError: true, case: 0, show: true });
    }
    if (lowercaseRegExp.test(value)) {
      setLCase({ ...lcase, isError: false, case: 0, show: true });
    } else {
      setLCase({ ...lcase, isError: true, case: 0, show: true });
    }

    if (uppercaseRegExp.test(value)) {
      setUCase({ ...ucase, isError: false, case: 0, show: true });
    } else {
      setUCase({ ...ucase, isError: true, case: 0, show: true });
    }
    if (specialCharRegExp.test(value)) {
      setSChar({ ...schar, isError: false, case: 0, show: true });
    } else {
      setSChar({ ...schar, isError: true, case: 0, show: true });
    }
  };

  const onClickHandler = () => {
    if (charlen.isError) {
      console.log('len');
      setCharLen({ ...charlen, case: 1 });
    }
    if (num.isError) {
      console.log('num');
      setNum({ ...num, case: 1 });
    }
    if (lcase.isError) {
      setLCase({ ...lcase, case: 1 });
    }
    if (ucase.isError) {
      setUCase({ ...ucase, case: 1 });
    }
    if (schar.isError) {
      setSChar({ ...schar, case: 1 });
    }
  };

  const [values1, setValues] = React.useState({
    password: '',
    showPassword: false,
  });

  const { signup, getInviteDetails } = useLogin();
  const handleClickShowPassword = () => {
    setValues({ ...values1, showPassword: !values1.showPassword });
  };

  const iff = (condition, then, otherwise) => (condition ? then : otherwise);

  const recaptchaRef = React.useRef();
  const { invitationId } = useParams();
  const [invitationEmailId, setInvitationEmailId] = useState('');
  const [emailReadonly, setEmailReadonly] = useState({});
  const [invitationDetails, setInvitationDetails] = useState(null);
  const _getInviteDetails = async () => {
    const invitationData = await getInviteDetails({ invitationId });
    setTimeout(() => {
      setInvitationEmailId(invitationData?.data?.emailId || '');
    }, 1500);
    setEmailReadonly(invitationData?.data?.emailId ? { readonly: true } : {});
    setInvitationDetails(invitationData?.data?.emailId ? invitationData?.data : null);
  };
  useEffect(() => {
    if (invitationId) {
      _getInviteDetails();
    }
  }, [invitationId]);

  return (
    <Formik
      initialValues={{
        firstName: '',
        lastName: '',
        emailId: invitationEmailId || '',
        password: '',
      }}
      validationSchema={LoginSchema}
      enableReinitialize
      onSubmit={async (values, actions) => {
        const token = await recaptchaRef.current.executeAsync();
        const defaultProfilePic =
          'https://www.google.co.in/imgres?imgurl=https%3A%2F%2Fcdn-icons-png.flaticon.com%2F512%2F3135%2F3135715.png&imgrefurl=https%3A%2F%2Fwww.flaticon.com%2Ffree-icon%2Fprofile_3135715&tbnid=dJxfuI6obnHK_M&vet=12ahUKEwig6br4pPT8AhWANbcAHQA6AVUQMygDegUIARDoAQ..i&docid=cO4ej6ajxZUMVM&w=512&h=512&q=profile&hl=en&authuser=0&ved=2ahUKEwig6br4pPT8AhWANbcAHQA6AVUQMygDegUIARDoAQ';
        let value = {
          ...values,
          roleId: invitationEmailId ? 1 : 0,
          profilePic: defaultProfilePic,
          isVerified: false,
          city: 3,
          state: 4,
          userDesignationText: invitationEmailId ? 'Community Representative' : 'Advisor',
          userDescription: invitationEmailId ? 'Community Rep' : 'Read Only',
          inviteId: invitationDetails?.id,
        };
        console.log(value, 'vvv');
        // return
        if (token) {
          value.recapToken = token;
          if (invitationDetails) {
            value = { ...value, invitationDetails };
          }
          signup(value, actions, recaptchaRef.current, invitationDetails);
        }
      }}
    >
      {({ values, errors, submitForm, isSubmitting, touched, setFieldValue, setFieldTouched }) => {
        console.log('Rutvik', values, errors);
        return (
          <Form autoComplete="off">
            <Grid container direction="column" justifyContent="flex-start" alignItems="center">
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Grid container alignItems="center" justifyContent="center">
                  <Grid item xs={12} className="commonFieldBox">
                    <InputLabel className="InputLabelFld" htmlFor="email">
                      First Name
                      <Box mb={1} component="span" display="inline-block" className="Asterisk">
                        *
                      </Box>
                    </InputLabel>
                    <FastField
                      as={TextField}
                      name="firstName"
                      id="outlined-basic"
                      variant="outlined"
                      type="text"
                      error={!!showErrIfTouched('firstName', errors, touched)}
                      helperText={showErrIfTouched('firstName', errors, touched)}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} className="commonFieldBox">
                    <InputLabel className="InputLabelFld" htmlFor="email">
                      Last Name
                      <Box mb={1} component="span" display="inline-block" className="Asterisk">
                        *
                      </Box>
                    </InputLabel>
                    <FastField
                      as={TextField}
                      name="lastName"
                      id="outlined-basic"
                      variant="outlined"
                      type="text"
                      error={!!showErrIfTouched('lastName', errors, touched)}
                      helperText={showErrIfTouched('lastName', errors, touched)}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} className="commonFieldBox">
                    <InputLabel className="InputLabelFld" htmlFor="email">
                      Email
                      <Box mb={1} component="span" display="inline-block" className="Asterisk">
                        *
                      </Box>
                    </InputLabel>
                    <FastField
                      as={TextField}
                      name="emailId"
                      id="outlined-basic"
                      variant="outlined"
                      type="email"
                      error={!!showErrIfTouched('emailId', errors, touched)}
                      helperText={showErrIfTouched('emailId', errors, touched)}
                      fullWidth
                      {...emailReadonly}
                    />
                  </Grid>
                  <Grid item xs={12} className="commonFieldBox">
                    <InputLabel className="InputLabelFld" htmlFor="email">
                      Password
                      <Box mb={1} component="span" display="inline-block" className="Asterisk">
                        *
                      </Box>
                    </InputLabel>
                    <Field
                      onChange={(e) => {
                        setFieldValue('password', e?.target?.value || '');
                        onChangeHandler(e);
                      }}
                      component={CustomTextInput}
                      name="password"
                      type={values1.showPassword ? 'text' : 'password'}
                      id="outlined-basic-2"
                      variant="outlined"
                      error={!!showErrIfTouched('password', errors, touched)}
                      helperText={showErrIfTouched('password', errors, touched)}
                      fullWidth
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={() => handleClickShowPassword()}
                            >
                              <Choose>
                                <When condition={values1.showPassword}>
                                  <VisibilityOutlinedIcon />
                                </When>
                                <Otherwise>
                                  <VisibilityOffOutlinedIcon />
                                </Otherwise>
                              </Choose>
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography component="div" variant="body2">
                      {lcase.show ? (
                        <Box className="FormMessageBox">
                          <Box className="FormMessageIcon">
                            {lcase.isError ? (
                              charlen.case == 1 ? (
                                <WarningRoundedIcon fontSize="small" className="RedColor" />
                              ) : (
                                <WarningRoundedIcon fontSize="small" className="OrangeColor" />
                              )
                            ) : (
                              <CheckCircleIcon fontSize="small" className="LightGreenColor" />
                            )}
                          </Box>
                          <Box className="FormMessageText">{lcase.message}</Box>
                        </Box>
                      ) : null}
                    </Typography>
                    <Typography component="div" variant="body2">
                      {ucase.show ? (
                        <Box className="FormMessageBox">
                          <Box className="FormMessageIcon">
                            {ucase.isError ? (
                              charlen.case == 1 ? (
                                <WarningRoundedIcon fontSize="small" className="RedColor" />
                              ) : (
                                <WarningRoundedIcon fontSize="small" className="OrangeColor" />
                              )
                            ) : (
                              <CheckCircleIcon fontSize="small" className="LightGreenColor" />
                            )}
                          </Box>
                          <Box className="FormMessageText">{ucase.message}</Box>
                        </Box>
                      ) : null}
                    </Typography>
                    <Typography component="div" variant="body2">
                      {schar.show ? (
                        <Box className="FormMessageBox">
                          <Box className="FormMessageIcon">
                            {schar.isError ? (
                              charlen.case == 1 ? (
                                <WarningRoundedIcon fontSize="small" className="RedColor" />
                              ) : (
                                <WarningRoundedIcon fontSize="small" className="OrangeColor" />
                              )
                            ) : (
                              <CheckCircleIcon fontSize="small" className="LightGreenColor" />
                            )}
                          </Box>
                          <Box className="FormMessageText">{schar.message}</Box>
                        </Box>
                      ) : null}
                    </Typography>
                    <Typography component="div" variant="body2">
                      {num.show ? (
                        <Box className="FormMessageBox">
                          <Box className="FormMessageIcon">
                            {num.isError ? (
                              charlen.case == 1 ? (
                                <WarningRoundedIcon fontSize="small" className="RedColor" />
                              ) : (
                                <WarningRoundedIcon fontSize="small" className="OrangeColor" />
                              )
                            ) : (
                              <CheckCircleIcon fontSize="small" className="LightGreenColor" />
                            )}
                          </Box>
                          <Box className="FormMessageText">{num.message}</Box>
                        </Box>
                      ) : null}
                    </Typography>
                    <Typography component="div" variant="body2">
                      {charlen.show ? (
                        <Box className="FormMessageBox">
                          <Box className="FormMessageIcon">
                            {charlen.isError ? (
                              charlen.case == 1 ? (
                                <WarningRoundedIcon fontSize="small" className="RedColor" />
                              ) : (
                                <WarningRoundedIcon fontSize="small" className="OrangeColor" />
                              )
                            ) : (
                              <CheckCircleIcon fontSize="small" className="LightGreenColor" />
                            )}
                          </Box>
                          <Box className="FormMessageText">{charlen.message}</Box>
                        </Box>
                      ) : null}
                    </Typography>
                    <Box mb={3} />
                  </Grid>
                  <Grid item xs={12}>
                    <Box className="Wrapper">
                      <Button
                        // disabled={isSubmitting || !isValid}
                        type="button"
                        className="loginButton"
                        variant="contained"
                        color="primary"
                        onClick={() => {
                          onClickHandler();
                          submitForm();
                        }}
                      >
                        Sign Up
                      </Button>
                      {isSubmitting && <CircularProgress size={24} className="ButtonProgress" />}
                    </Box>
                    <Box mt={3} textAlign="center">
                      <Typography variant="body2">
                        Already have an account?
                        <Button
                          style={{ textDecoration: 'inherit' }}
                          className="SignUpButton LinkTypeButton"
                          onClick={() => setValue(0)}
                        >
                          Log In
                        </Button>
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
              <ReCAPTCHA ref={recaptchaRef} sitekey={KEY.SITE} size="invisible" />
            </Grid>
          </Form>
        );
      }}
    </Formik>
  );
};

SignUp.propTypes = {
  setValue: PropTypes.number.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default SignUp;
