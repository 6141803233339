/* eslint-disable */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  Grid,
  Button,
  Typography,
  Box,
  Paper,
  Avatar,
  LinearProgress,
  Checkbox,
  FormControlLabel,
  InputLabel,
} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
// import Carousel from "react-simply-carousel";
import Carousel from 'react-material-ui-carousel';
import {
  Link,
  DirectLink,
  Element,
  Events,
  animateScroll,
  scrollSpy,
  scroller,
} from 'react-scroll';
import Cookies from 'js-cookie';
import CryptoJS from 'crypto-js';
import useCommunityList from '../../../hooks/useCommunityList';
import Residents from '../Residents/Residents';
import InsightsBy from '../InsightsBy/InsightsBy';
import AboutCommunity from '../AboutCommunity/AboutCommunity';
import CostOfLiving from '../CostOfLiving/CostOfLiving';
import Amenities from '../Amenities/Amenities';
import Jobs from '../Jobs/Jobs';
import CommunityCard from '../CommunityList/CommunityCard/CommunityCard';
import CommunityMap from './CommunityMap/CommunityMap';
import HouseList from '../HouseList/HouseList';
import ArrowLeft from '../../../assets/images/Arrow-Left.png';
import ArrowRight from '../../../assets/images/Arrow-Right.png';
import { ReactComponent as PersonIcon } from '../../../assets/images/User.svg';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';

const CommunityProfileTracker = ({ communityDetails }) => {
  const [progress, setProgress] = React.useState(18);
  const [showProgressDetails, setShowProgressDetails] = React.useState(false);
  const [state, setState] = React.useState({});
  const educationObjRep = communityDetails?.education?.[0];
  const gettingAroundObjRep = communityDetails?.gettingAround?.[0];
  const jobsObjRep = communityDetails?.jobs?.[0];
  const residentsObjRep = communityDetails?.residents?.[0];
  const climateObjRep = communityDetails?.climate?.[0];
  const amenitiesDescObjRep = communityDetails?.amenityDescription?.[0];

  const amenitiesObjRep = communityDetails?.amenities?.[0];

  const costOfLivingObjRep = communityDetails?.costOfLiving?.[0];

  const healthcareInsightsObjRep = communityDetails?.healthInsights?.[0];

  const healthcareFacilityObjRep = communityDetails.healthFacility?.[0];

  const communityVideoObjRep = communityDetails?.communityVideo?.[0];

  useEffect(() => {
    setState({
      aboutDesc: !!communityDetails?.aboutDesc,
      jobsDesc: !!jobsObjRep?.jobsDesc != '',
      residentsDesc: !!residentsObjRep?.residentsDesc != '',
      gettingAroundDesc:
        !!gettingAroundObjRep &&
        (gettingAroundObjRep?.dedicatedBikingPaths ||
          gettingAroundObjRep?.dedicatedWalkingPaths ||
          gettingAroundObjRep?.regionalAirport ||
          gettingAroundObjRep?.localAirport ||
          gettingAroundObjRep?.publicTransType !== '' ||
          gettingAroundObjRep?.gettingAroundDesc !== ''),
      education:
        !!educationObjRep &&
        (educationObjRep?.preDesc != '' ||
          educationObjRep?.postDesc != '' ||
          educationObjRep?.publicType ||
          educationObjRep?.privateType ||
          educationObjRep?.faithBasedPrivate ||
          educationObjRep?.specialNeedsPrivate ||
          educationObjRep?.publicTechnicalJuniorCollege ||
          educationObjRep?.privateTechnicalJuniorCollege ||
          educationObjRep?.publicFourYearCollegeUniversity ||
          educationObjRep?.privatecFourYearCollegeUniversity ||
          educationObjRep?.homeschoolResourceGroups ||
          educationObjRep?.charter),
      climateDesc:
        (!!climateObjRep &&
          (climateObjRep?.climateDesc !== '' || climateObjRep?.climateType !== '')) ||
        false,
      amenityDesc: !!(
        (communityDetails?.amenityDescription &&
          amenitiesDescObjRep &&
          amenitiesDescObjRep?.amenityDesc !== '' &&
          communityDetails.amenities &&
          amenitiesObjRep &&
          amenitiesObjRep?.amenityDesc !== '') ||
        false
      ),
      costOfLiving: !!(
        communityDetails?.costOfLiving &&
        costOfLivingObjRep &&
        (costOfLivingObjRep?.costOfLivingType !== '' || costOfLivingObjRep?.costOfLivingDesc !== '')
      ),
      profile: !!communityDetails?.userrole[0]?.userDescription,
      healthcare: !!(
        (communityDetails?.healthInsights &&
          healthcareInsightsObjRep &&
          healthcareInsightsObjRep?.healthCareDesc !== '' &&
          communityDetails.healthFacility &&
          healthcareFacilityObjRep &&
          healthcareFacilityObjRep?.facilityAddress !== '') ||
        false
      ),
      communityVideo: !!(communityVideoObjRep?.approvedStatus?.toLowerCase() == 'approved'),
    });
    {
      console.log(communityVideoObjRep?.approvedStatus?.toLowerCase() == 'approved', 9696);
    }
    let prog = 0;
    const leng = 100 / 11;
    if (communityDetails?.aboutDesc) prog += leng;
    if ((jobsObjRep && jobsObjRep?.jobsDesc != '') || false) prog += leng;
    if ((residentsObjRep && residentsObjRep?.residentsDesc != '') || false) prog += leng;
    if (
      (gettingAroundObjRep &&
        (gettingAroundObjRep?.dedicatedBikingPaths ||
          gettingAroundObjRep?.dedicatedWalkingPaths ||
          gettingAroundObjRep?.regionalAirport ||
          gettingAroundObjRep?.localAirport ||
          gettingAroundObjRep?.publicTransType !== '' ||
          gettingAroundObjRep?.gettingAroundDesc !== '')) ||
      false
    )
      prog += leng;
    if (
      educationObjRep &&
      (educationObjRep?.preDesc != '' ||
        educationObjRep?.postDesc != '' ||
        educationObjRep?.publicType ||
        educationObjRep?.privateType ||
        educationObjRep?.faithBasedPrivate ||
        educationObjRep?.specialNeedsPrivate ||
        educationObjRep?.publicTechnicalJuniorCollege ||
        educationObjRep?.privateTechnicalJuniorCollege ||
        educationObjRep?.publicFourYearCollegeUniversity ||
        educationObjRep?.privatecFourYearCollegeUniversity ||
        educationObjRep?.homeschoolResourceGroups ||
        educationObjRep?.charter)
    )
      prog += leng;
    if (
      (climateObjRep && (climateObjRep?.climateDesc !== '' || climateObjRep?.climateType !== '')) ||
      false
    )
      prog += leng;
    if (
      (communityDetails?.amenityDescription &&
        amenitiesDescObjRep &&
        amenitiesDescObjRep?.amenityDesc !== '' &&
        communityDetails.amenities &&
        amenitiesObjRep &&
        amenitiesObjRep?.amenityDesc !== '') ||
      false
    )
      prog += leng;

    if (
      (communityDetails?.healthInsights &&
        healthcareInsightsObjRep &&
        healthcareInsightsObjRep?.healthCareDesc !== '' &&
        communityDetails.healthFacility &&
        healthcareFacilityObjRep &&
        healthcareFacilityObjRep?.facilityAddress !== '') ||
      false
    )
      prog += leng;
    if (
      (communityDetails?.costOfLiving &&
        costOfLivingObjRep &&
        (costOfLivingObjRep?.costOfLivingType !== '' ||
          costOfLivingObjRep?.costOfLivingDesc !== '')) ||
      false
    )
      prog += leng;
    if (communityDetails?.userrole[0]?.userDescription || false) prog += leng;
    if (
      (communityVideoObjRep && communityVideoObjRep?.approvedStatus?.toLowerCase() == 'approved') ||
      false
    )
      prog += leng;

    setProgress(parseInt(prog));
  }, []);
  return (
    <Paper className="WhitePaper TopBoxCD" component="div">
      <Box className="CommonDetailBoxGridSecond ProfileTrackerContainer">
        <Grid container>
          <Grid item xs={12}>
            <Grid
              container
              className="ProfileTrackerPerBox"
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography variant="body1" component="div" className="ProfileTitle">
                {communityDetails?.communityNameText} Profile
              </Typography>
              <Typography
                color={progress === 0 ? 'secondary' : 'primary'}
                variant="body2"
                className="ProfileTrackerPer"
              >
                {progress}% complete
              </Typography>
            </Grid>
            <LinearProgress className="ProfileProgress" variant="determinate" value={progress} />
          </Grid>
          <Grid item xs={12}>
            <Typography
              variant="body2"
              color="primary"
              onClick={() => setShowProgressDetails(!showProgressDetails)}
              className="ShowDetails"
            >
              {!showProgressDetails ? 'Show' : 'Hide'} Details{' '}
              <img
                className="ShowDetailsIcon"
                src={
                  require(`../../../assets/images/ChevronCol${
                    !showProgressDetails ? 'Down' : 'Up'
                  }.svg`).default
                }
                alt="show/hide details Icon"
              />
            </Typography>
          </Grid>
        </Grid>
        <If condition={showProgressDetails}>
          <Grid container>
            <Grid item xs={12} className="ProfileTitleDesc">
              <Typography variant="caption" component="div">
                To maximize your reach on the platform and be considered a top represented
                community, make sure your page has:
              </Typography>
            </Grid>
            <Grid item xs={12} className="ProfileHead">
              <Typography variant="h6" component="div">
                Profile
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    className="ProfileTrackerCheckbox"
                    readonly="readonly"
                    icon={<RadioButtonUncheckedIcon />}
                    checkedIcon={<CheckCircleIcon />}
                    checked={state?.profile}
                    // onChange={handleChange}
                    name="Profile"
                    color="primary"
                  />
                }
                label="Set Up Your Community Representative Profile"
              />
            </Grid>
            <Grid item xs={12} className="ProfileHead">
              <Typography variant="h6" component="div">
                Community Page Sections
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    className="ProfileTrackerCheckbox"
                    readonly="readonly"
                    icon={<RadioButtonUncheckedIcon />}
                    checkedIcon={<CheckCircleIcon />}
                    checked={state?.amenityDesc}
                    // onChange={handleChange}
                    name="Amenities"
                    color="primary"
                  />
                }
                label="Amenities"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    className="ProfileTrackerCheckbox"
                    readonly="readonly"
                    icon={<RadioButtonUncheckedIcon />}
                    checkedIcon={<CheckCircleIcon />}
                    checked={state?.aboutDesc}
                    // onChange={handleChange}
                    name="About"
                    color="primary"
                  />
                }
                label="About"
              />

              <FormControlLabel
                control={
                  <Checkbox
                    className="ProfileTrackerCheckbox"
                    readonly="readonly"
                    icon={<RadioButtonUncheckedIcon />}
                    checkedIcon={<CheckCircleIcon />}
                    checked={state?.jobsDesc}
                    // onChange={handleChange}
                    name="Jobs"
                    color="primary"
                  />
                }
                label="Jobs"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    className="ProfileTrackerCheckbox"
                    readonly="readonly"
                    icon={<RadioButtonUncheckedIcon />}
                    checkedIcon={<CheckCircleIcon />}
                    checked={state?.residentsDesc}
                    // onChange={handleChange}
                    name="Residents"
                    color="primary"
                  />
                }
                label="Residents"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    className="ProfileTrackerCheckbox"
                    readonly="readonly"
                    icon={<RadioButtonUncheckedIcon />}
                    checkedIcon={<CheckCircleIcon />}
                    checked={state?.costOfLiving}
                    // onChange={handleChange}
                    name="CostOfLiving"
                    color="primary"
                  />
                }
                label="Cost of Living"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    className="ProfileTrackerCheckbox"
                    readonly="readonly"
                    icon={<RadioButtonUncheckedIcon />}
                    checkedIcon={<CheckCircleIcon />}
                    checked={state?.climateDesc}
                    // onChange={handleChange}
                    name="Climate"
                    color="primary"
                  />
                }
                label="Climate"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    className="ProfileTrackerCheckbox"
                    readonly="readonly"
                    icon={<RadioButtonUncheckedIcon />}
                    checkedIcon={<CheckCircleIcon />}
                    checked={state?.gettingAroundDesc}
                    // onChange={handleChange}
                    name="GettingAround"
                    color="primary"
                  />
                }
                label="Getting Around"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    className="ProfileTrackerCheckbox"
                    readonly="readonly"
                    icon={<RadioButtonUncheckedIcon />}
                    checkedIcon={<CheckCircleIcon />}
                    checked={state?.education}
                    // onChange={handleChange}
                    name="Education"
                    color="primary"
                  />
                }
                label="Education"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    className="ProfileTrackerCheckbox"
                    readonly="readonly"
                    icon={<RadioButtonUncheckedIcon />}
                    checkedIcon={<CheckCircleIcon />}
                    checked={state?.healthcare}
                    // onChange={handleChange}
                    name="healthcare"
                    color="primary"
                  />
                }
                label="Healthcare"
              />
            </Grid>
            <Grid item xs={12} className="ProfileHead">
              <Typography variant="h6" component="div">
                Premium Features
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    className="ProfileTrackerCheckbox"
                    readonly="readonly"
                    icon={<RadioButtonUncheckedIcon />}
                    checkedIcon={<CheckCircleIcon />}
                    checked={state?.communityVideo}
                    // onChange={handleChange}
                    name="communityVideo"
                    color="primary"
                  />
                }
                label="Add a Custom Move to Happy Video"
              />
            </Grid>
          </Grid>
        </If>
      </Box>
    </Paper>
  );
};

// CommunityList.defaultProps = {
//   showSearch: true,
// };
export default CommunityProfileTracker;
