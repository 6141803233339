/* eslint-disable*/
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Button,
  Typography,
} from '@material-ui/core';
import { Formik, Form, Field } from 'formik';
import CryptoJS from 'crypto-js';
import Cookies from 'js-cookie';
import { makeStyles } from '@material-ui/core/styles';
import useCommunityList from '../../../../hooks/useCommunityList';
import useLogin from '../../../../hooks/useAuth';
import { useParams, useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import { InputAdornment } from '@material-ui/core';
import LanguageOutlinedIcon from '@material-ui/icons/LanguageOutlined';
import LocalPhoneOutlinedIcon from '@material-ui/icons/LocalPhoneOutlined';
import LocationOnOutlinedIcon from '@material-ui/icons/LocationOnOutlined';
import { AccountCircle, LocationOn, Phone, Public } from '@material-ui/icons';
import { FormHelperText } from '@material-ui/core';
import FacilityDeleteModal from '../CommunityHealthFacility/FacilityDeleteModal';
import NumberFormat from 'react-number-format';
import { FACILITY_TYPES } from '../../../../constants/globalConstants';

const useStyles = makeStyles((theme) => ({
  dialog: {
    width: '80%', // Adjust the width of the dialog
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '600px',
    margin: 'auto',
    marginTop: theme.spacing(4),
  },
  field: {
    marginBottom: theme.spacing(2),
    width: '100%',
  },
  submitButton: {
    marginTop: theme.spacing(2),
  },
  deleteFacilityButton: {
    color: 'Red',
    marginTop: '10px',
  },
}));

const validationSchema = yup.object().shape({
  facilityType: yup.string().required('Facility type is required'),
  facilityName: yup.string().required('Facility name is required'),
  facilityAddress: yup.string().required('Facility address is required'),
});

const ModalFormik = ({ isOpen, onClose, selectedFacility, fetchData, modalType }) => {
  console.log(selectedFacility);
  const classes = useStyles();
  const {
    addCommunityHealthFacility,
    getCommunityHealthFacility,
    updateCommunityHeathFacility,
  } = useCommunityList();
  const { getRoleData } = useLogin();
  const params = useParams();

  const key = 'iFrasCwdJh';
  let bytes = null;

  let loggedInUserData = null;
  const userCookie = Cookies.get('user');
  if (userCookie) {
    bytes = CryptoJS.AES.decrypt(userCookie, key);
    loggedInUserData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  } else {
    window.location.hash = '#/app/home-page';
  }

  const [userId] = useState(loggedInUserData?.id);

  const [healthFacility, setHealthFacility] = useState([]);
  const [showDeleteBox, setShowDeleteBox] = useState(false);
  const [responseMessage, setResponseMessage] = useState('');
  const [showFetchMessage, setShowFetchMessage] = useState(false);

  useEffect(() => {
    // Call the getCommunityHealthFacility function and handle the returned data
    const fetchData = async () => {
      try {
        const response = await getCommunityHealthFacility({
          communityId: params?.id,
          repId: loggedInUserData?.id,
        });

        // Assuming the response contains an array of health facilities
        console.log(response.data, 'RPF');
        setHealthFacility(response.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData(); // Call the fetchData function when the component mounts
  }, []);

  const handleBackToParent = (e) => {
    setShowDeleteBox(true);
  };

  const onTakeMeback = (event) => {
    setShowDeleteBox(false);
  };

  return (
    <Dialog open={isOpen} onClose={onClose} classes={{ paper: classes.dialog }}>
      {showDeleteBox && (
        <>
          <FacilityDeleteModal
            status={true}
            navigateUrl={`../../community-details/${params?.id}`}
            onClose={onTakeMeback}
            onCloseParent={onClose}
            selectedFacility={selectedFacility}
            setShowDeleteBox={setShowDeleteBox}
            fetchData={fetchData}
          ></FacilityDeleteModal>
        </>
      )}
      <DialogTitle className={classes.dialogTitle}>
        {modalType === 'add' ? 'Add Health Care Facility' : 'Edit Health Care Facility'}
      </DialogTitle>
      <DialogContent>
        <Formik
          initialValues={
            modalType == 'add'
              ? {
                  communityId: params?.id,
                  repId: loggedInUserData?.id,
                  facilityId: '',
                  facilityType: 'Assisted Living Facility',
                  facilityName: '',
                  facilityAddress: '',
                  facilityPhone: null,
                  facilityWebUrl: '',
                }
              : {
                  communityId: params?.id,
                  facilityId: selectedFacility?.id,
                  facilityType: selectedFacility?.facilityType,
                  facilityName: selectedFacility?.facilityName,
                  facilityAddress: selectedFacility?.facilityAddress || '',
                  facilityPhone: selectedFacility?.facilityPhone || null,
                  facilityWebUrl: selectedFacility?.facilityWebUrl || '',
                }
          }
          validationSchema={validationSchema}
          onSubmit={async (values, actions) => {
            const formattedPhoneNumber = values.facilityPhone
              ? values.facilityPhone.replace(/\D/g, '')
              : '';

            const phoneNumberAsInteger = parseInt(formattedPhoneNumber, 10); // Convert to integer

            const updatedValues = {
              ...values,
              facilityPhone: phoneNumberAsInteger,
            };

            try {
              if (modalType === 'add') {
                // Call addCommunityHealthFacility for adding a new facility
                const response = await addCommunityHealthFacility(updatedValues, {
                  headers: {
                    'Content-Type': 'application/json',
                  },
                });
                setResponseMessage(response.message); // Update response message
                onClose();
                // Display "Added" message and then fetch data after a short delay
                setShowFetchMessage(true); // Display "Added" message
                setTimeout(() => {
                  setShowFetchMessage(false); // Hide "Added" message
                  fetchData(); // Fetch data after a short delay
                  setShowFetchMessage(true); // Display "Facilities retrieved successfully" message
                }, 1500); // You can adjust the delay time (in milliseconds) as needed
                console.log(response, 'REPP');
              } else if (modalType === 'edit') {
                // Call updateCommunityHeathFacility for updating an existing facility
                const response = await updateCommunityHeathFacility(
                  selectedFacility.id,
                  updatedValues,
                );
                setResponseMessage(response.message); // Update response message
                onClose();
                // Display "Added" message and then fetch data after a short delay
                setShowFetchMessage(true); // Display "Added" message
                setTimeout(() => {
                  setShowFetchMessage(false); // Hide "Added" message
                  fetchData(); // Fetch data after a short delay
                  setShowFetchMessage(true); // Display "Facilities retrieved successfully" message
                }, 1500); // You can adjust the delay time (in milliseconds) as needed
                console.log(response);
              }
            } catch (error) {
              console.error(error.response.data);
            }
          }}
        >
          {({ handleSubmit, handleChange, values, errors, touched }) => (
            <Form className={classes.form} onSubmit={handleSubmit}>
              <InputLabel className="InputLabelFld" htmlFor="facilityType">
                Type
              </InputLabel>
              {/* <ErrorMessage name="facilityType" component="div" className={classes.error} /> */}
              <FormControl className={classes.field}>
                <Select
                  name="facilityType"
                  id="facilityType"
                  value={values.facilityType}
                  onChange={handleChange}
                  error={touched.facilityType && !!errors.facilityType}
                >
                  {FACILITY_TYPES?.map((facilityType) => (
                    <MenuItem value={facilityType}>{facilityType}</MenuItem>
                  ))}
                  {/* <MenuItem value="Assisted Living Facility">Assisted Living Facility</MenuItem>
                  <MenuItem value="Behavioral Health Facility">Behavioral Health Facility</MenuItem>
                  <MenuItem value="Home Healthcare">Home Healthcare</MenuItem>
                  <MenuItem value="Hospice Services">Hospice Services</MenuItem>
                  <MenuItem value="Hospital - General Acute Care">
                    Hospital - General Acute Care
                  </MenuItem>
                  <MenuItem value="Hospital - Specialty Care">Hospital - Specialty Care</MenuItem>
                  <MenuItem value="Long Term Care Facility">Long Term Care Facility</MenuItem>
                  <MenuItem value="Outpatient Specialty Care Facility">
                    Outpatient Specialty Care Facility
                  </MenuItem> */}
                </Select>
                {touched.facilityType && errors.facilityType && (
                  <Typography color="error">{errors.facilityType}</Typography>
                )}
              </FormControl>
              <Typography>Facility Name</Typography>
              <Field
                as={TextField}
                className={classes.field}
                variant="outlined"
                name="facilityName"
                value={values.facilityName}
              />
              {touched.facilityName && errors.facilityName && (
                <Typography color="error">{errors.facilityName}</Typography>
              )}
              <Typography>Address</Typography>
              <Field
                as={TextField}
                className={classes.field}
                variant="outlined"
                name="facilityAddress"
                value={values.facilityAddress}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <LocationOnOutlinedIcon className="FacilityIcons" />
                    </InputAdornment>
                  ),
                }}
              />
              {touched.facilityAddress && errors.facilityAddress && (
                <Typography color="error">{errors.facilityAddress}</Typography>
              )}
              <FormHelperText className="ModalHelpertext">Optional</FormHelperText>
              <Typography>Phone Number</Typography>
              <Field
                as={TextField}
                className={classes.field}
                variant="outlined"
                name="facilityPhone"
                type="tel" // Use "tel" type for phone numbers
                value={values.facilityPhone}
                customInput={TextField}
                component={NumberFormat} // Use NumberFormat as the component
                format="(###) ###-####"
                onValueChange={(values) => {
                  // Provide raw unformatted value to handleChange
                  handleChange({ target: { name: 'facilityPhone', value: values.value } });
                }}
                InputProps={{
                  autoComplete: 'off',
                  startAdornment: (
                    <InputAdornment position="start">
                      <LocalPhoneOutlinedIcon className="FacilityIcons" />
                    </InputAdornment>
                  ),
                }}
              />
              <FormHelperText className="ModalHelpertext">Optional</FormHelperText>
              <Typography>Website (enter URL)</Typography>
              <Field
                as={TextField}
                className={classes.field}
                variant="outlined"
                name="facilityWebUrl"
                value={values.facilityWebUrl}
                type="text"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <LanguageOutlinedIcon className="FacilityIcons" />
                    </InputAdornment>
                  ),
                }}
              />
              <Button
                className={classes.submitButton}
                variant="contained"
                color="primary"
                type="submit"
              >
                {modalType === 'add' ? 'ADD FACILITY' : 'PUBLISH FACILITY'}
              </Button>
              <Button
                className={classes.deleteFacilityButton}
                // variant="contained"
                color="primary"
                onClick={(e) => {
                  handleBackToParent(e);
                }}
                style={{ display: modalType === 'add' ? 'none' : 'block' }}
              >
                DELETE FACILITY
              </Button>
            </Form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

export default ModalFormik;
