/* eslint-disable*/
import React, { useState, useEffect } from 'react';
import {
  Grid,
  Paper,
  Typography,
  Box,
  Avatar,
  Divider,
  Button,
  List,
  ListItem,
} from '@material-ui/core';
import { ReactComponent as PersonIcon } from '../../../assets/images/User.svg';
import { ReactComponent as EditPenIconGreen } from '../../../assets/images/EditPenGreen.svg';
import Cookies from 'js-cookie';
import CryptoJS from 'crypto-js';
import useLogin from '../../../hooks/useAuth';

const CostOfLiving = (props) => {
  const key = 'iFrasCwdJh';
  let bytes = null;
  let loggedInUserData = null;
  const userCookie = Cookies.get('user');
  if (userCookie) {
    bytes = CryptoJS.AES.decrypt(userCookie, key);
    loggedInUserData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  }

  const [communityDetails, setCommunityDetails] = useState(props.communityInfo);
  const [isAccessible, setIsAccessible] = useState(false);
  const [repDetails, setRepDetails] = useState();
  const { getUserDataToAdmin } = useLogin();

  const getRepDetails = async () => {
    try {
      if (communityDetails?.costOfLiving?.[0]?.isAdminEntry) {
        const userDataResult = await getUserDataToAdmin({
          userIdd: communityDetails?.costOfLiving?.[0]?.repId,
        });
        setRepDetails(userDataResult.usersResult);
      }
    } catch (error) {
      console.error('Async function error:', error);
    }
  };

  useEffect(() => {
    setIsAccessible(
      (props?.loggedInUserData?.id &&
        communityDetails?.userrole?.[0]?.id === props?.loggedInUserData?.id) ||
        props?.loggedInUserData?.roleId === 2,
    );
    getRepDetails();
  }, [communityDetails]);

  return (
    <Paper className="WhitePaper" component="div">
      <Grid container>
        <Grid item xs={12} className="BoxHeadingContainer">
          <Typography color="primary" variant="h2" className="BoxHeading">
            Cost of Living
          </Typography>
          {/* {console.log(
            communityDetails?.costOfLiving.find(
              (x) => x.repId === communityDetails?.userrole[0]?.id,
            )?.costOfLivingDesc,
            5000,
          )} */}
          <If
            condition={
              props?.isAccessible &&
              !props?.publicView &&
              ((communityDetails?.costOfLiving &&
                communityDetails?.costOfLiving?.[0]?.costOfLivingType) ||
                (communityDetails?.costOfLiving &&
                  communityDetails?.costOfLiving?.[0]?.costOfLivingDesc))
            }
          >
            <Button
              type="submit"
              className="EditCommonButton LinkTypeButton"
              startIcon={<EditPenIconGreen />}
              size="small"
              color="primary"
              href={'/#/app/community-details-edit-cost-of-living/' + communityDetails?.id}
            >
              Edit
            </Button>
          </If>
        </Grid>
        <Grid item xs={6} className="AutoWidth CDTotal">
          <Box className="CommonDetailBoxGrid">
            <Typography variant="h4" component="div" className="CDNumber">
              {communityDetails?.medianHomePrice
                ? `$${communityDetails?.medianHomePrice?.toLocaleString()}`
                : 'Unavailable'}
            </Typography>
            <Typography variant="body2" component="div">
              Median Home Listing Price
            </Typography>
          </Box>
        </Grid>
        {/* <Grid item xs={6} className="AutoWidth CDTotal">
          <Box className="CommonDetailBoxGrid">
            <Typography variant="h4" component="div" className="CDNumber">
              {communityDetails?.stateIncomeTax}%
            </Typography>
            <Typography variant="body2" component="div">
              State Income Tax
            </Typography>
          </Box>
        </Grid> */}
        <If condition={communityDetails?.costOfLiving?.[0]?.costOfLivingType}>
          <Grid item xs={12} className="">
            <Divider className="DividerCD" />
          </Grid>

          <Grid item xs={12} className="CommonTypeSection CostOfLivingTypeSection">
            <Typography variant="body2" className="InsightLabel">
              Cost of Living
            </Typography>

            <List>
              <ListItem>
                <Typography component="div" variant="body2">
                  {communityDetails?.costOfLiving &&
                    communityDetails?.costOfLiving?.[0]?.costOfLivingType}
                </Typography>
              </ListItem>
            </List>
          </Grid>
        </If>

        <If
          condition={
            // communityDetails?.costOfLiving&&communityDetails?.costOfLiving[0]?.costOfLivingType ||

            (communityDetails?.costOfLiving &&
              communityDetails?.costOfLiving?.[0]?.costOfLivingType) ||
            (communityDetails?.costOfLiving &&
              communityDetails?.costOfLiving?.[0]?.costOfLivingDesc)
          }
        >
          <Grid item xs={12}>
            <Box className="ChatBox CommonDetailBoxGrid">
              {communityDetails?.costOfLiving?.[0]?.costOfLivingDesc && (
                <Box className="ChatContent">
                  <Typography variant="body2" className="ChatText" color="primary">
                    {communityDetails?.costOfLiving?.[0]?.costOfLivingDesc}
                  </Typography>
                </Box>
              )}
              {console.log(repDetails, 5000)}
              {((communityDetails?.costOfLiving &&
                communityDetails?.costOfLiving?.[0]?.costOfLivingType !== '') ||
                (communityDetails?.costOfLiving &&
                  communityDetails?.costOfLiving?.[0]?.costOfLivingDesc !== '')) && (
                <Box className="UserInfoName UserInfoIP">
                  <Avatar
                    className="UserAvatar small"
                    alt={`${
                      communityDetails?.costOfLiving?.[0]?.isAdminEntry
                        ? repDetails?.firstName
                        : communityDetails?.userrole[0]?.firstName
                    } `}
                    src={
                      window.location.hostname === 'localhost'
                        ? `//localhost:9001/${
                            communityDetails?.costOfLiving?.[0]?.isAdminEntry
                              ? repDetails?.profilePic
                              : communityDetails?.userrole[0]?.profilePic
                          }`
                        : `/api/${
                            communityDetails?.costOfLiving?.[0]?.isAdminEntry
                              ? repDetails?.profilePic
                              : communityDetails?.userrole[0]?.profilePic
                          }`
                    }
                  >
                    <PersonIcon />
                  </Avatar>
                  <Box className="UserInfoText">
                    <Typography variant="h6">
                      <Box component="span" className="FirstName">
                        {communityDetails?.costOfLiving?.[0]?.isAdminEntry
                          ? repDetails?.firstName
                          : communityDetails?.userrole[0]?.firstName}
                      </Box>
                      <Box component="span" className="LastName">
                        {communityDetails?.costOfLiving?.[0]?.isAdminEntry
                          ? repDetails?.lastName
                          : communityDetails?.userrole[0]?.lastName}
                      </Box>
                    </Typography>
                    <Typography variant="caption">
                      {communityDetails?.costOfLiving?.[0]?.isAdminEntry
                        ? repDetails?.userDesignationText
                        : communityDetails?.userrole[0]?.userDesignationText}
                    </Typography>
                  </Box>
                </Box>
              )}
            </Box>
          </Grid>
        </If>

        <Grid item xs={12}>
          <Box>
            <If condition={props?.isAccessible && !props?.publicView}>
              <If
                condition={
                  !(
                    (communityDetails?.costOfLiving &&
                      communityDetails?.costOfLiving?.[0]?.costOfLivingType) ||
                    (communityDetails?.costOfLiving &&
                      communityDetails?.costOfLiving?.[0]?.costOfLivingDesc)
                  )
                }
              >
                <Grid item xs={12}>
                  <Divider className="DividerCD"></Divider>
                </Grid>
                <Typography variant="body2" className="InsightLabel">
                  How far does a dollar go in your Community? What are some benefits to living here
                  that people may not know?
                </Typography>
              </If>
            </If>
          </Box>
        </Grid>
        <If condition={props?.isAccessible && !props?.publicView}>
          <Grid item xs={12}>
            <If
              condition={
                !(
                  (communityDetails?.costOfLiving &&
                    communityDetails?.costOfLiving?.[0]?.costOfLivingType) ||
                  (communityDetails?.costOfLiving &&
                    communityDetails?.costOfLiving?.[0]?.costOfLivingDesc)
                )
              }
            >
              <Box className="Wrapper ButtonWrapper InsightButtonContainer">
                <Button
                  variant="outlined"
                  color="primary"
                  fullWidth
                  href={'/#/app/community-details-edit-cost-of-living/' + communityDetails?.id}
                >
                  <Box className="AddButton">Add Insight</Box>
                </Button>
              </Box>
            </If>
          </Grid>
        </If>
      </Grid>
    </Paper>
  );
};

export default CostOfLiving;
