/* eslint-disable*/
import React, { useState, useEffect, useRef } from 'react';
import {
  Grid,
  Paper,
  Typography,
  Box,
  Button,
  Avatar,
  Divider,
  Popover,
  IconButton,
  Collapse,
  FormControlLabel,
  Switch,
} from '@material-ui/core';
import { useParams, useNavigate } from 'react-router-dom';
import { ReactComponent as PersonIcon } from '../../../assets/images/User.svg';
import AddCircleOutlineSharpIcon from '@material-ui/icons/AddCircleOutlineSharp';
import { ReactComponent as EditPenIconGreen } from '../../../assets/images/EditPenGreen.svg';
import { ReactComponent as EditPenIconGreen2 } from '../../../assets/images/EditIcon-2.svg';
import { ReactComponent as MapPinIcon } from '../../../assets/images/MapPin.svg';
import { ReactComponent as PhoneIcon } from '../../../assets/images/PhoneIcon.svg';
import { ReactComponent as WebsiteIcon } from '../../../assets/images/WebsiteIcon.svg';
import FilterListIcon from '@material-ui/icons/FilterList';
import CloseIcon from '@material-ui/icons/Close';
import Cookies from 'js-cookie';
import CryptoJS from 'crypto-js';
import useCommunityList from '../../../hooks/useCommunityList';
import { useLocation } from 'react-router-dom';
import CommunityAddHealthFacility from '../CommunityDetails/CommunityAddHealthFacility/CommunityAddHealthFacility';
import useLogin from '../../../../src/hooks/useAuth';

const Healthcare = (props) => {
  const [healthFacility, setHealthFacility] = useState([]);
  const [communityDetails, setCommunityDetails] = useState(props.communityInfo);
  const [selectedFacility, setSelectedFacility] = useState(null);
  const [isAccessible, setIsAccessible] = useState(false);
  const [repDetails, setRepDetails] = useState();

  const { getUserDataToAdmin } = useLogin();

  const buttonRef = useRef(null);
  //console.log(communityDetails?.healthFacility?.length, 'CMD');
  //console.log(props?.loggedInUserData?.id, '5151');
  //console.log(healthFacility, 'hhh');

  const { getCommunityHealthFacility, formatUrl } = useCommunityList();

  const { getRoleData } = useLogin();

  const navigate = useNavigate();
  const handleFilterClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleFilterClose = () => {
    setAnchorEl(null);
  };

  const params = useParams();
  let communityID = params?.id;
  //console.log(communityID, 'COMMU_ID');

  const key = 'iFrasCwdJh';
  let bytes = null;

  let loggedInUserData = null;
  const userCookie = Cookies.get('user');
  if (userCookie) {
    bytes = CryptoJS.AES.decrypt(userCookie, key);
    loggedInUserData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  } else {
    // window.location.hash = '#/app/home-page';
  }

  const [userId] = useState(loggedInUserData?.id);

  //console.log(healthFacility, 'HF');

  const [isEditing, setIsEditing] = useState(false);

  const insightUpdateId = communityDetails?.healthInsights[0]?.repId;

  const handleEditClick = (facility) => {
    setSelectedFacility(facility);

    //console.log(facility);
    setIsEditing(true); // Set the editing state to true
    //console.log(facility.id);
    navigate(`/app/community-edit-health-facility/${communityDetails?.id}/${facility.id}`);
  };

  // Function to handle adding, set isEditing to false when adding a new facility
  const handleAdd = () => {
    setIsEditing(false);
  };

  //console.log(selectedFacility, 'SSS');

  useEffect(() => {
    //console.log('Selected Facility in Healthcare:', selectedFacility);
  }, [selectedFacility]);

  const formatPhoneNumber = (phoneNumber) => {
    const digits = phoneNumber?.replace(/\D/g, '');
    return digits?.replace(/^(\d{3})(\d{3})(\d{4})$/, '$1-$2-$3');
  };

  const [filterFacilityType, setFilterFacilityType] = useState(null);
  const [selectedFacilityTypes, setSelectedFacilityTypes] = useState([]);
  const [filteredFacilities, setFilteredFacilities] = useState(healthFacility);
  const [originalHealthFacility, setOriginalHealthFacility] = useState([]);
  const [collapseStates, setCollapseStates] = useState({});

  const handleFacilityTypeCollapse = (facilityType) => {
    setCollapseStates((prevCollapseStates) => ({
      ...prevCollapseStates,
      [facilityType]: !prevCollapseStates[facilityType],
    }));
  };

  //console.log(filteredFacilities, 'FNF');

  const comRepId = communityDetails?.healthFacility[0]?.repId;
  //console.log(comRepId, 765);

  useEffect(() => {
    const fetchData = async () => {
      //console.log(communityDetails?.userrole[0]?.id, 4545);
      try {
        const response = await getCommunityHealthFacility({
          communityId: params?.id,
          // repId: communityDetails?.healthFacility[0]?.repId,
        });

        // Store the fetched data in both originalHealthFacility and healthFacility
        //console.log(response.data, 4546);
        setOriginalHealthFacility(response.data);
        setHealthFacility(response.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  const handleFilterClick1 = (facilityType) => {
    setSelectedFacilityTypes((prevSelected) => {
      const updatedSelected = prevSelected.includes(facilityType)
        ? prevSelected.filter((selected) => selected !== facilityType)
        : [...prevSelected, facilityType];

      return updatedSelected;
    });

    // Remove focus to immediately visually reflect the selection or deselection
    document.activeElement.blur();
  };

  const resetFilters = () => {
    setSelectedFacilityTypes([]);
    // Reset filteredFacilities to the same data as healthFacility
    setFilteredFacilities({ ...healthFacility }); // Make a shallow copy
    setAnchorEl(null);
  };

  const seeResults = () => {
    // Apply filtering logic to generate filteredData
    const filteredData = {};

    if (selectedFacilityTypes.length === 0) {
      // No filters selected, use the original data
      setFilteredFacilities({ ...healthFacility });
      // setFilteredResultsCount(numberOfEntries);
    } else {
      selectedFacilityTypes.forEach((facilityType) => {
        if (healthFacility.hasOwnProperty(facilityType)) {
          filteredData[facilityType] = healthFacility[facilityType];
        }
      });

      // Update filteredFacilities with the filteredData
      setFilteredFacilities(filteredData);
    }
  };

  // ... (other code)

  useEffect(() => {
    // Initialize filteredFacilities with the original healthFacility data
    setFilteredFacilities(healthFacility);
  }, [healthFacility]);

  const filteredResultsCount =
    selectedFacilityTypes?.length > 0
      ? selectedFacilityTypes.reduce((count, selectedType) => {
          return count + (healthFacility[selectedType]?.length || 0);
        }, 0)
      : 0;

  let numberOfEntries = 0;
  // const keys = Object.keys(healthFacility);

  // // Checking if the keys are arrays and getting their lengths
  // keys.forEach((key) => {
  //   if (Array.isArray(healthFacility[key])) {
  //     numberOfEntries += healthFacility[key].length;
  //   }
  // });

  const handleFilterButtonClick = (facilityType) => {
    setSelectedFacilityTypes((prevSelected) => {
      const updatedSelected = prevSelected.includes(facilityType)
        ? prevSelected.filter((selected) => selected !== facilityType)
        : [...prevSelected, facilityType];

      // Apply filtering logic immediately
      const filteredData = {};
      if (updatedSelected.length === 0) {
        // No filters selected, use the original data
        setFilteredFacilities({ ...healthFacility });
      } else {
        updatedSelected.forEach((type) => {
          if (healthFacility.hasOwnProperty(type)) {
            filteredData[type] = healthFacility[type];
          }
        });
        setFilteredFacilities(filteredData);
      }

      // Focus on a different element to remove the active class
      document.activeElement.blur();

      return updatedSelected;
    });
  };

  const getRepDetails = async () => {
    try {
      if (communityDetails?.healthInsights[0]?.isAdminEntry) {
        const userDataResult = await getUserDataToAdmin({
          userIdd: communityDetails?.healthInsights[0]?.repId,
        });
        setRepDetails(userDataResult.usersResult);
      }
    } catch (error) {
      console.error('Async function error:', error);
    }
  };

  useEffect(() => {
    setIsAccessible(
      (props?.loggedInUserData?.id &&
        communityDetails?.userrole?.[0]?.id === props?.loggedInUserData?.id) ||
        props?.loggedInUserData?.roleId === 2,
    );
    getRepDetails();
  }, [communityDetails]);

  //console.log(props, '71771');

  return (
    <Paper className="WhitePaper" component="div">
      <Grid container>
        <Grid item xs={12} className="BoxHeadingContainer">
          <Typography color="primary" variant="h2" className="BoxHeading">
            Healthcare
          </Typography>
        </Grid>
      </Grid>
      <If condition={(props?.isAccessible && !props?.publicView) || false}>
        <Box>
          <Typography variant="body2" className="AddLabel">
            Inputting healthcare facilities helps users find your community when filtering.
          </Typography>
        </Box>
      </If>
      <Grid item xs={12}>
        <If condition={isAccessible && !props?.publicView}>
          <Box>
            <Button
              className="AddButtonBox"
              variant="outlined"
              color="primary"
              fullWidth
              // href="community-add-health-facility"
              href={'/#/app/community-add-health-facility/' + communityDetails?.id}
              onClick={handleAdd}
              startIcon={<AddCircleOutlineSharpIcon />}
            >
              Add A facility
            </Button>
          </Box>
        </If>
        <Divider className="DividerCD"></Divider>
      </Grid>
      <Grid item xs={12} className="FacilityListFilter">
        {healthFacility && Object.keys(healthFacility).length > 0 && (
          // <If
          //   condition={
          //    (isAccessible||
          //     props?.publicView) || false
          //   }
          // >
          <>
            <Box className="MobileFacilityList">
              <Box className="FilterTagContent">
                {Object.keys(healthFacility).map(
                  (facilityType) =>
                    healthFacility[facilityType].length > 0 && (
                      <Button
                        key={facilityType}
                        className={`FilterTag ${
                          selectedFacilityTypes.includes(facilityType) ? 'Selected' : ''
                        }`}
                        onClick={() => handleFilterButtonClick(facilityType)}
                      >
                        {facilityType}
                      </Button>
                    ),
                )}
              </Box>
            </Box>

            <Box className="FacilityFilterBox DesktopFilter">
              <Typography variant="body1" component="div" className="LightgreyColor">
                {selectedFacilityTypes.length === 0
                  ? `${communityDetails?.healthFacility.length} Result${
                      communityDetails?.healthFacility.length === 1 ? '' : 's'
                    }`
                  : `${filteredResultsCount} Result${filteredResultsCount === 1 ? '' : 's'}`}
              </Typography>

              <Button
                className="SearchButton"
                aria-label="more"
                id="long-button"
                aria-controls={open ? 'long-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup="true"
                onClick={handleFilterClick}
                startIcon={<FilterListIcon color="primary" />}
                variant="outlined"
              >
                {selectedFacilityTypes.length > 0
                  ? selectedFacilityTypes.length > 1
                    ? `Filters(${selectedFacilityTypes.length})`
                    : `Filter(${selectedFacilityTypes.length})`
                  : 'Filter'}
                <Box className="FilterCount" component="span"></Box>
              </Button>
              <Popover
                id="long-menu"
                open={open}
                anchorEl={anchorEl}
                onClose={handleFilterClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                className="MobileFilter"
              >
                <Paper className="CommonFilterBox AmenityFilters">
                  <Box className="FilterHeader">
                    <Typography variant="h4">Facility Filters</Typography>
                    <CloseIcon
                      color="primary"
                      className="CloseButton"
                      onClick={handleFilterClose}
                    />
                  </Box>
                  <Box className="FilterBody">
                    <Box className="FilterTagBox">
                      <Typography variant="body1">
                        <b>Type</b>
                      </Typography>
                      <Box className="FilterTagContent">
                        {Object.keys(healthFacility).map(
                          (facilityType) =>
                            healthFacility[facilityType].length > 0 && (
                              <Button
                                key={facilityType}
                                className={`FilterTag ${
                                  selectedFacilityTypes.includes(facilityType) ? 'Selected' : ''
                                }`}
                                onClick={() => handleFilterClick1(facilityType)}
                              >
                                {facilityType}
                              </Button>
                            ),
                        )}
                      </Box>
                    </Box>
                  </Box>
                  <Box className="FilterFooter">
                    <Divider />
                    <Box className="FilterAction">
                      <Button variant="outlined" size="small" onClick={resetFilters}>
                        Reset All
                      </Button>
                      <Button onClick={seeResults} variant="contained" color="primary" size="small">
                        {/* See {`${filteredResultsCount} Result${filteredResultsCount === 1 ? '' : 's'}`} */}
                        {selectedFacilityTypes.length > 0
                          ? selectedFacilityTypes.length > 1
                            ? `See (${selectedFacilityTypes.length}) Results`
                            : `See (${selectedFacilityTypes.length}) Result`
                          : 'See Result'}
                        <Box className="FilterCount" component="span"></Box>
                      </Button>
                    </Box>
                  </Box>
                </Paper>
              </Popover>
            </Box>
          </>
          // </If>
        )}
      </Grid>
      {/* <If condition={!props?.publicView}> */}
      <Grid item xs={12} className="FacilityList">
        {filteredFacilities &&
          Object.entries(filteredFacilities).map(([facilityType, facilities]) => (
            <Grid container className="FacilityListContent" key={facilityType}>
              <Grid item xs={12} sm={12}>
                {facilities.length > 0 && (
                  <Typography
                    variant="h3"
                    component="div"
                    className="FacilityListHeading"
                    color="primary"
                  >
                    {facilityType}
                  </Typography>
                )}
              </Grid>
              <Collapse in={collapseStates[facilityType]} className="FacilityListCollapse">
                <Grid item xs={12} sm={12} className="FacilityListCollapseRow">
                  <Grid container>
                    {facilities.map((facility, index) => (
                      <Grid item xs={12} sm={6} key={index} className="FacilityColumn">
                        <Box className="FacilityNameBox">
                          <Typography variant="body2" component="div" className="FacilityListName">
                            {facility?.facilityWebUrl ? (
                              <a
                                href={formatUrl(facility?.facilityWebUrl)}
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{ color: '#486877', textDecoration: 'underline' }} // Custom color
                              >
                                <span className="facility-text">{facility.facilityName}</span>
                              </a>
                            ) : (
                              <span
                                style={{
                                  color: '#486877',
                                  textDecoration: 'none',
                                  display: 'inline-block',
                                }}
                              >
                                {facility.facilityName}
                              </span>
                            )}
                          </Typography>
                          <If condition={isAccessible && !props?.publicView}>
                            <IconButton
                              className="FacilityEditButton"
                              color="primary"
                              // href={'/#/app/community-add-health-facility/' + communityDetails?.id}
                              onClick={() => handleEditClick(facility)} // Pass a callback function
                            >
                              <EditPenIconGreen2 />
                            </IconButton>
                          </If>
                        </Box>
                        <Box component="div" className="FacilityInfo">
                          <MapPinIcon />
                          <Typography
                            variant="body2"
                            component="div"
                            className="FacilityInfoContent BlackColor"
                          >
                            {facility?.facilityAddress}
                          </Typography>
                        </Box>
                        {facility?.facilityPhone?.length > 0 ? (
                          <Box component="div" className="FacilityInfo">
                            <PhoneIcon />
                            <Typography
                              variant="body2"
                              component="div"
                              className="FacilityInfoContent BlackColor"
                            >
                              {formatPhoneNumber(facility?.facilityPhone)}
                            </Typography>
                          </Box>
                        ) : null}
                        {facility?.facilityWebUrl?.length > 0 ? (
                          <Box component="div" className="FacilityInfo">
                            <WebsiteIcon />
                            <Typography
                              variant="body2"
                              component="div"
                              className="FacilityInfoContent BlackColor"
                            >
                              <a
                                href={formatUrl(facility?.facilityWebUrl)}
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{ color: '#486877', textDecoration: 'underline' }} // Custom color
                              >
                                {facility?.facilityWebUrl}
                              </a>
                            </Typography>
                          </Box>
                        ) : null}
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
              </Collapse>
              <FormControlLabel
                className="FacilityListShowHideButton"
                control={
                  <Switch
                    className="ShowHideButton"
                    checked={collapseStates[facilityType]}
                    onChange={() => handleFacilityTypeCollapse(facilityType)}
                  />
                }
              />
            </Grid>
          ))}
      </Grid>
      {/* </If> */}
      {/* {console.log(communityDetails?.healthInsights, 'CHECK')} */}
      <Grid item xs={12} className="ChatBoxContainer ChatBoxHealthcare">
        <Box className="ChatBox CommonDetailBoxGrid">
          <If
            condition={
              // !props?.publicView &&
              communityDetails?.healthInsights &&
              communityDetails?.healthInsights.length > 0 &&
              communityDetails?.healthInsights.sort(
                (a, b) => new Date(b.updatedAt) - new Date(a.updatedAt),
              )[0]?.healthCareDesc !== ''
            }
          >
            <Box className="ChatContent">
              <If
                condition={
                  isAccessible &&
                  !props?.publicView &&
                  communityDetails?.healthInsights?.find(
                    (x) => x.repId === communityDetails?.userrole[0]?.id || x.isAdminEntry,
                  )?.healthCareDesc
                }
              >
                <Button
                  className="EditCommonButtonFloat"
                  variant="outlined"
                  color="primary"
                  size="small"
                  startIcon={<EditPenIconGreen />}
                  href={'/#/app/community-details-health-insights/' + communityDetails?.id}
                >
                  Edit
                </Button>
              </If>
              {/* {console.log(communityDetails?.healthInsights, 111)} */}
              {communityDetails?.healthInsights?.length > 0 && (
                <Typography variant="body2" className="ChatText">
                  {
                    communityDetails?.healthInsights.sort(
                      (a, b) => new Date(b.updatedAt) - new Date(a.updatedAt),
                    )[0]?.healthCareDesc // Sort by updatedAt in descending order // Accessing healthCareDesc property of the most recent object
                  }
                </Typography>
              )}
            </Box>
            <Box className="UserInfoName UserInfoIP">
              <Avatar
                className="UserAvatar small"
                alt={`${
                  communityDetails?.healthInsights?.[0]?.isAdminEntry
                    ? repDetails?.firstName
                    : communityDetails?.userrole[0]?.firstName
                }}`}
                src={
                  window.location.hostname === 'localhost'
                    ? `//localhost:9001/${
                        communityDetails?.healthInsights?.[0]?.isAdminEntry
                          ? communityDetails?.userrole[0]?.profilePic
                          : repDetails?.profilePic
                      }`
                    : `/api/${communityDetails?.userrole[0]?.profilePic}`
                }
              >
                <PersonIcon />
              </Avatar>
              <Box className="UserInfoText">
                <Typography variant="h6">
                  <Box component="span" className="FirstName">
                    {communityDetails?.healthInsights?.[0]?.isAdminEntry
                      ? repDetails?.firstName
                      : communityDetails?.userrole[0]?.firstName}
                  </Box>
                  <Box component="span" className="LastName">
                    {communityDetails?.healthInsights?.[0]?.isAdminEntry
                      ? repDetails?.lastName
                      : communityDetails?.userrole[0]?.lastName}
                  </Box>
                </Typography>
                <Typography variant="caption">
                  {communityDetails?.healthInsights?.[0]?.isAdminEntry
                    ? repDetails?.userDesignationText
                    : communityDetails?.userrole[0]?.userDesignationText}
                </Typography>
              </Box>
            </Box>
          </If>
        </Box>
      </Grid>
      <If condition={isAccessible && !props?.publicView}>
        <Grid item xs={12}>
          <If
            condition={
              !communityDetails?.healthInsights?.find(
                (x) => x.repId === communityDetails?.userrole[0]?.id || x.isAdminEntry,
              )?.healthCareDesc
            }
          >
            <Grid item xs={12}>
              <Box>
                <Typography variant="body2" className="InsightLabel">
                  Describe your community’s healthcare. Are there any specialties or awards
                  facilities are known for?
                </Typography>
              </Box>
            </Grid>
            <Box className="Wrapper ButtonWrapper InsightButtonContainer">
              <Button
                variant="outlined"
                color="primary"
                fullWidth
                href={'/#/app/community-details-health-insights/' + communityDetails?.id}
              >
                <Box className="AddButton">Add Insight</Box>
              </Button>
            </Box>
          </If>
        </Grid>
      </If>
      {/* Render the EditHealthFacility component only when editing is enabled */}
      {selectedFacility && comRepId !== undefined && (
        <CommunityAddHealthFacility
          comRepId={comRepId}
          editMode={isEditing} // Pass the edit mode flag
          initialValues={selectedFacility} // Pass the selected facility data for editing
        />
      )}
    </Paper>
  );
};

export default Healthcare;
