/* eslint-disable*/
import React, { useState, useEffect } from 'react';
import { Grid, Paper, Typography, Box, Avatar, Button, Divider } from '@material-ui/core';
import { ReactComponent as PersonIcon } from '../../../assets/images/User.svg';
import { ReactComponent as EditPenIconGreen } from '../../../assets/images/EditPenGreen.svg';
import { backendUrl } from '../../../constants/globalConstants';
import Cookies from 'js-cookie';
import CryptoJS from 'crypto-js';
import useLogin from '../../../hooks/useAuth';

const Residents = (props) => {
  const key = 'iFrasCwdJh';
  let bytes = null;
  let loggedInUserData = null;
  const userCookie = Cookies.get('user');
  if (userCookie) {
    bytes = CryptoJS.AES.decrypt(userCookie, key);
    loggedInUserData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  }
  const [communityDetails, setCommunityDetails] = useState(props.communityInfo);
  const [isAccessible, setIsAccessible] = useState(false);
  const [repDetails, setRepDetails] = useState();
  const { getUserDataToAdmin } = useLogin();

  const getRepDetails = async () => {
    try {
      if (communityDetails?.residents?.[0]?.isAdminEntry) {
        const userDataResult = await getUserDataToAdmin({
          userIdd: communityDetails?.residents?.[0]?.repId,
        });
        setRepDetails(userDataResult.usersResult);
      }
    } catch (error) {
      console.error('Async function error:', error);
    }
  };

  useEffect(() => {
    setIsAccessible(
      (props?.loggedInUserData?.id &&
        communityDetails?.userrole?.[0]?.id === props?.loggedInUserData?.id) ||
        props?.loggedInUserData?.roleId === 2,
    );
    getRepDetails();
  }, [communityDetails]);
  return (
    <Paper id="Section6" className="WhitePaper" component="div">
      <Grid container>
        <Grid item xs={12} className="BoxHeadingContainer">
          <Typography color="primary" variant="h2" className="BoxHeading">
            Residents
          </Typography>
          <If
            condition={
              props?.isAccessible &&
              !props?.publicView &&
              communityDetails?.residents?.[0] &&
              communityDetails?.residents?.[0]?.residentsDesc != ''
            }
          >
            <Button
              type="submit"
              className="EditCommonButton LinkTypeButton"
              startIcon={<EditPenIconGreen />}
              size="small"
              color="primary"
              href={'/#/app/community-details-edit-residents/' + communityDetails?.id}
            >
              Edit
            </Button>
          </If>
        </Grid>
        <Grid item xs={6} className="AutoWidth CDTotal">
          <Box className="CommonDetailBoxGrid">
            <Typography variant="h4" component="div" className="CDNumber">
              {communityDetails?.population
                ? Number(
                    (communityDetails?.population / communityDetails?.landAreaSqMile).toFixed(1),
                  )?.toLocaleString()
                : 'Unavailable'}
            </Typography>
            <Typography variant="body2" component="div">
              Population Per Square Mile
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={6} className="AutoWidth ">
          <Box className="CommonDetailBoxGrid">
            <Typography variant="h4" component="div" className="CDNumber">
              {communityDetails?.medianAge
                ? `${communityDetails?.medianAge?.toLocaleString()} years`
                : 'Unavailable'}
            </Typography>
            <Typography variant="body2" component="div">
              Median Age
            </Typography>
          </Box>
        </Grid>

        <If condition={props?.isAccessible && !props?.publicView}>
          <If
            condition={
              (communityDetails?.residents?.[0] &&
                communityDetails?.residents?.[0]?.residentsDesc == '') ||
              communityDetails?.residents?.length == 0
            }
          >
            <Grid item xs={12}>
              <Divider className="DividerCD"></Divider>
            </Grid>
            <Grid item xs={12}>
              <Box>
                <Typography variant="body2" className="InsightLabel">
                  Add what people should know about the residents of your Community.
                </Typography>
              </Box>
            </Grid>
          </If>
        </If>
        {/* {props?.isAccessible && ( */}
        <Grid item xs={12}>
          <Box className="ChatBox CommonDetailBoxGrid">
            {communityDetails?.residents?.[0] && communityDetails?.residents?.[0]?.residentsDesc && (
              <Box className="ChatContent">
                <Typography
                  variant="body2"
                  className="ChatText"
                  style={{ whiteSpace: 'pre-line' }}
                  color="primary"
                >
                  {communityDetails?.residents?.[0]?.residentsDesc}
                </Typography>
              </Box>
            )}
          </Box>
        </Grid>
        {/* )} */}
        {
          // props?.isAccessible &&
          communityDetails?.residents?.[0] && communityDetails?.residents?.[0]?.residentsDesc != '' && (
            <Box className="UserInfoName UserInfoIP">
              <Avatar
                className="UserAvatar small"
                alt={`${
                  communityDetails?.residents?.[0]?.isAdminEntry
                    ? repDetails?.firstName
                    : communityDetails?.userrole[0]?.firstName
                } `}
                src={
                  window.location.hostname === 'localhost'
                    ? `//localhost:9001/${
                        communityDetails?.residents?.[0]?.isAdminEntry
                          ? repDetails?.profilePic
                          : communityDetails?.userrole[0]?.profilePic
                      }`
                    : `/api/${
                        communityDetails?.residents?.[0]?.isAdminEntry
                          ? repDetails?.profilePic
                          : communityDetails?.userrole[0]?.profilePic
                      }`
                }
              >
                <PersonIcon />
              </Avatar>
              <Box className="UserInfoText">
                <Typography variant="h6">
                  <Box component="span" className="FirstName">
                    {communityDetails?.residents?.[0]?.isAdminEntry
                      ? repDetails?.firstName
                      : communityDetails?.userrole[0]?.firstName}
                  </Box>
                  <Box component="span" className="LastName">
                    {communityDetails?.residents?.[0]?.isAdminEntry
                      ? repDetails?.lastName
                      : communityDetails?.userrole[0]?.lastName}
                  </Box>
                </Typography>
                <Typography variant="caption">
                  {communityDetails?.residents?.[0]?.isAdminEntry
                    ? repDetails?.userDesignationText
                    : communityDetails?.userrole[0]?.userDesignationText}
                </Typography>
              </Box>
            </Box>
          )
        }
        {/* <If condition={props?.isAccessible && !props?.publicView}> */}

        <If condition={props?.isAccessible && !props?.publicView}>
          <Grid item xs={12}>
            <If
              condition={
                !(
                  communityDetails?.residents &&
                  communityDetails?.residents?.length > 0 &&
                  communityDetails?.residents?.[0]?.residentsDesc
                )
              }
            >
              <Box className="Wrapper ButtonWrapper InsightButtonContainer">
                <Button
                  variant="outlined"
                  color="primary"
                  fullWidth
                  href={'/#/app/community-details-edit-residents/' + communityDetails?.id}
                >
                  {/* <img
                    src={require('../../../assets/images/Insight.svg').default}
                    alt="Insight Icon"
                  /> */}
                  <Box className="AddButton">Add Insight</Box>
                </Button>
              </Box>
            </If>
          </Grid>
        </If>
      </Grid>
    </Paper>
  );
};

export default Residents;
