export const drawerWidth = 210;
export const homesBaseUrlForCommunity =
  'https://homes.movingtohappy.net/planetre/Community?CommunityId=';

export const roles = {
  homeshopper: {
    roleShortName: 'homeshopper',
    roleAvatar: '',
  },
  communityrep: {
    roleShortName: 'communityrep',
    roleAvatar: '',
  },
  admin: { roleShortName: 'admin', roleAvatar: '' },
};

export const entityEnum = {
  0: 'Communities',
  1: 'Houses',
  2: 'Jobs',
};
Object.freeze(entityEnum);

export const jBoardImporter = {
  lagrange: [],
  trussville: [10265, 10261, 10262, 10263, 10264, 11701],
  atlanta: [9213],
};
Object.freeze(jBoardImporter);

export const KEY = {
  /* Google Recapcha key account details - faisal.khan@azularc.com */
  SITE: '6Ld-cMckAAAAAMFlyPC3SX_v1b94zeZ6lJR3l00h',
  SECRECT: '6Ld-cMckAAAAANVIn7UXB2D-q8Q61riDGWki1JxG',
};

export const GA_MEASUREMENT_ID = 'G-ZH2L1LG8SW';

export const a = 'a';
export const backendUrl =
  window.location.hostname === 'localhost'
    ? `http://localhost:9001/`
    : `${window.location.origin}/api/`;

export const COST_OF_LIVING_TYPE = [
  'Below the national average',
  'Similar to the national average',
  'Above the national average',
];

export const PUBLIC_TRANSPORTATION_TYPES = [
  { key: 'multiplePublicTransportationOptions', label: 'Multiple public transportation options' },
  { key: 'limitedPublicTransportation', label: 'Limited public transportation' },
  { key: 'noPublicTransportation', label: 'No public transportation' },
];

export const OTHER_TRANSPORTATION_TYPES = [
  { key: 'dedicatedWalkingPaths', label: 'Dedicated walking paths' },
  { key: 'dedicatedBikingPaths', label: 'Dedicated biking paths' },
  { key: 'localAirport', label: 'Local airport' },
  { key: 'regionalAirport', label: 'Regional airport' },
];

export const SCHOOL_TYPES = [
  { key: 'publicType', label: 'Public' },
  { key: 'privateType', label: 'Private' },
  { key: 'faithBasedPrivate', label: 'Faith based private' },
  { key: 'specialNeedsPrivate', label: 'Special needs private' },
  { key: 'publicTechnicalJuniorCollege', label: 'Public technical / junior college' },
  { key: 'privateTechnicalJuniorCollege', label: 'Private technical / junior college' },
  { key: 'publicFourYearCollegeUniversity', label: 'Public four year college / university' },
  { key: 'privatecFourYearCollegeUniversity', label: 'Private four year college / university' },
  { key: 'homeschoolResourceGroups', label: 'Homeschool resource group(s)' },
  { key: 'charter', label: 'Charter' },
];

export const FACILITY_TYPES = [
  'Acupuncture',
  'Addiction Treatment',
  'Alternative Medicine',
  'Audiology',
  'Behavioral Health',
  'Brain and Spinal Care',
  'Cancer Treatment',
  'Cardiology',
  "Children's Health",
  'Chiropractic',
  'Cosmetic',
  'Dental Care',
  'Dermatology',
  'Diabetic Health',
  'Dialysis Treatment',
  'Emergency Care',
  'Foot Care',
  'Home Health',
  'Internal Medicine',
  "Men's Health",
  'Occupational Health',
  'Ophthalmology',
  'Orthopedic Health',
  'Physical Therapy',
  'Primary Healthcare',
  'Pulmonary',
  'Senior Care',
  'Special Needs',
  'Specialized Care',
  'Speech Therapy',
  'Sports Medicine',
  'Urology',
  'Weight Loss',
];
