/* eslint-disable*/
import React, { useState, useEffect } from 'react';
import {
  Grid,
  Button,
  Typography,
  Box,
  Paper,
  Avatar,
  Divider,
  Snackbar,
  Link,
} from '@material-ui/core';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import ShowMoreText from 'react-show-more-text';
import PhotoCameraOutlinedIcon from '@material-ui/icons/PhotoCameraOutlined';
import { Alert } from '@material-ui/lab';
import { Formik, Field, Form, ErrorMessage, FastField } from 'formik';
import Cookies from 'js-cookie';
import CryptoJS from 'crypto-js';
import * as Yup from 'yup';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import WarningRoundedIcon from '@material-ui/icons/WarningRounded';

import { ReactComponent as PersonIcon } from '../../../assets/images/User.svg';
import { ReactComponent as EditPenIconGreen } from '../../../assets/images/EditPenGreen.svg';
import Videoposter from '../../../assets/images/Video-poster.png';
import { backendUrl } from '../../../constants/globalConstants';
import { ReactComponent as EditPenIcon } from '../../../assets/images/EditPenWhite.svg';
import CommunityCard from '../CommunityList/CommunityCard/CommunityCard';
import useCommunityList from '../../../hooks/useCommunityList';

const CommunityCoverPhoto = ({
  communityDetails,
  isAccessible,
  setPublicView,
  publicView,
  fillCommunityDetails,
  fileInput = React.useRef(),
}) => {
  const [snackbar, setSnackbar] = useState(false);
  const navigate = useNavigate();
  const { updateCoverPhoto } = useCommunityList();
  const [isMobile, setIsMobile] = useState(false);
  const closeSnackbar = () => {
    setSnackbar(false);
  };
  const loc = useLocation();
  useEffect(() => {
    const mediaQuery = window.matchMedia('(max-width: 500px)'); // Adjust the max-width based on your mobile breakpoint

    const handleResize = () => {
      setIsMobile(mediaQuery.matches);
    };

    handleResize(); // Check initial size

    mediaQuery.addEventListener('change', handleResize);

    return () => {
      mediaQuery.removeEventListener('change', handleResize);
    };
  }, []);
  return (
    <>
      {/* <Formik
        enableReinitialize
        initialValues={{ coverPhoto: undefined }}
        validationSchema={Yup.object().shape({
          coverPhoto: Yup.mixed()
            .test('fileSize', 'File size too large, max file size is 5mb', (fileVal) =>
              fileVal ? fileVal.size <= 5000000 : true,
            )
            .test('fileType', 'Incorrect file type', (fileVal) =>
              fileVal ? ['image/png', 'image/jpg', 'image/jpeg'].includes(fileVal.type) : true,
            ),
        })}
        validateOnChange
        onSubmit={async (values, { resetForm }) => {
          try {
            console.log(values);
            const formData = new FormData();
            formData.append('communityId', communityDetails?.id);
            formData.append('coverPhoto', values?.coverPhoto);

            const result = await updateCoverPhoto(formData);
            setTimeout(() => {
              if (result?.status) {
                resetForm();
                fillCommunityDetails();
              }
            }, 1000);
          } catch (e) {
            console.error(e);
          }
        }}
      >
        {({ isValid, setFieldValue, submitForm, errors, validateForm }) => {
          return (
            <>
              <input
                ref={fileInput}
                type="file"
                accept=".png,.jpg,.jpeg"
                style={{ display: 'none' }}
                onChange={async (event) => {
                  setFieldValue('coverPhoto', event.currentTarget.files[0]);
                  const validForm = await validateForm({
                    coverPhoto: event.currentTarget.files[0],
                  });
                  if (validForm?.coverPhoto) setSnackbar(true);
                  submitForm();
                }}
              />

              <Snackbar open={snackbar} autoHideDuration={6000} onClose={closeSnackbar}>
                <Alert
                  elevation={6}
                  variant="filled"
                  onClose={closeSnackbar}
                  severity="success"
                  icon={<WarningRoundedIcon color="secondary" fontSize="inherit" />}
                  action={
                    <Link
                      color="primary"
                      underline="none"
                      aria-label="close"
                      onClick={closeSnackbar}
                      className="AlertDismissButton"
                    >
                      Dismiss
                    </Link>
                  }
                >
                  {errors?.coverPhoto}
                </Alert>
              </Snackbar>
            </>
          );
        }}
      </Formik> */}
      <If condition={isAccessible}>
        <If condition={!communityDetails?.images}>
          <Box
            className="CardBannerSection EmptyCardBannerSection"
            style={{
              backgroundImage: `url(${
                require(`../../../assets/images/community-Empty-Image.jpg`).default
              })`,
            }}
          >
            <If condition={!publicView}>
              <Box className="EmptyCardBannerOverlay">
                <Typography variant="h4" component="div" className="EmptyCoverHeading">
                  Add a Cover Photo
                </Typography>
                <Typography variant="body2" component="div" className="EmptyCoverSubHeading">
                  Add a cover photo of your community to stand out. A placeholder is shown to the
                  public by default.
                </Typography>
                <Box className="EmptyCoverButtonWrapper">
                  <Button
                    variant="outlined"
                    className="EmptyCoverButton"
                    startIcon={<PhotoCameraOutlinedIcon />}
                    // onClick={() => fileInput?.current.click()}
                    onClick={() =>
                      navigate(`/app/community-details-update-cover-photo/${communityDetails?.id}`)
                    }
                  >
                    Add Photo
                  </Button>
                </Box>
              </Box>
            </If>
          </Box>
        </If>
        <If condition={communityDetails?.images}>
          {console.log(backendUrl + communityDetails?.images, 2223)}
          <Box
            className="CardBannerSection"
            style={{
              backgroundImage: `url(${backendUrl + communityDetails?.images})`,
            }}
          >
            <If condition={!publicView}>
              <Box className="EditCDButton EditBannerPhoto">
                <Button
                  variant="outlined"
                  className="EditBannerPhotoButton"
                  startIcon={<EditPenIcon />}
                  size="small"
                  onClick={() =>
                    navigate(`/app/community-details-update-cover-photo/${communityDetails?.id}`)
                  }
                >
                  Edit Photo
                </Button>
              </Box>
            </If>
          </Box>
        </If>
      </If>
      {console.log(isMobile, 9898)}
      <If condition={!isAccessible && communityDetails?.isRepresented && !isMobile}>
        <If condition={!publicView}>
          <Box
            className="CardBannerSection"
            style={{
              backgroundImage: `url(${
                communityDetails?.images
                  ? backendUrl + communityDetails?.images
                  : require(`../../../assets/images/community-Empty-Image.jpg`).default
              })`,
            }}
          ></Box>
        </If>
      </If>

      <CommunityCard
        cm={communityDetails}
        listView={false}
        favStatus={loc?.state?.isFavourite}
        actionButton={true}
        isAccessible={isAccessible}
        setPublicView={setPublicView}
        publicView={publicView}
        fileInput={fileInput}
      />
    </>
  );
};

export default CommunityCoverPhoto;
